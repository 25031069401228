<template>
  <div class="">
    <PDialog
      v-if="!inline"
      :header="title"
      :modal="true"
      :visible.sync="display"
      :containerStyle="{ width: $screen.breakpoint == 'sm' ? '100vw' : '60vw' }"
    >
      <PTabView :activeIndex.sync="activeTab">
        <PTabPanel header="Signature manuelle">
          <SignatureDrawer @save="saveSignature" />
          <PMessage v-if="error" severity="error" :closable="false"
            >Vous n'avez pas apprové la signature.</PMessage
          >
        </PTabPanel>
        <PTabPanel header="Choisir un fichier">
          <FilePondUploader
            ref="fileUploader"
            class="col-12 md:col-12"
            @save="fileSaved"
          />
        </PTabPanel>
      </PTabView>
      <template #footer>
        <PButton
          label="Annuler"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton
          label="Valider"
          icon="pi pi-check"
          @click="save(signatureUrl)"
          autofocus
        />
      </template>
    </PDialog>
  </div>
</template>

<script>
import SignatureDrawer from "./SignatureDrawer.vue";
import FilePondUploader from "../../../uploader/FilePondUploader.vue";

export default {
  props: {
    title: {
      type: String,
      default: "Editeur de signature",
    },
    inline: {
      type: Boolean,
      require: true, //If false then will function as a dialog
    },
    items: {
      type: Array,
      default: () => [],
    },
    referentielName: {
      type: String,
      default: "demandeurSelector",
    },
    // showActions: {
    //   type:  Boolean,
    //   default: false
    // },
    fields: {
      type: Array,
      default: () => [
        "#",
        "index",
        { key: "nom", label: "Nom" },
        { key: "prenom", label: "Prénom" },
        { key: "phone", label: "Téléphone" },
        { key: "commune.nom", label: "Commune" },
      ],
    },
  },
  components: {
    SignatureDrawer,
    FilePondUploader,
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      error: false,
      signatureUrl: null,
      participants: [],
    };
  },
  created() {},
  watch: {
    items() {
      this.participants = [...this.items];
    },
    participants() {
      this.$emit("change", this.participants);
    },
  },
  computed: {
    emptyReferentielItem() {
      return {
        titre: null,
        code: null,
      };
    },
    tableFields() {
      return this.fields;
    },
    options() {
      return this.structures.map((item) => ({ value: item, text: item.libelle }));
    },
  },
  methods: {
    extraQueryArgsParamBuilder() {
      return `regroupements=NON_AFFECTE`;
    },
    resetContent() {
      this.signatureUrl = null;
    },
    show() {
      this.resetContent();
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    fileSaved(files) {
      // this.$refs.fileUploader.getFilesUrls()
      if (files.length > 0) {
        this.signatureUrl = files[0];
        this.error = !(typeof urlSignature == "string" || urlSignature instanceof String);
      }
    },
    saveSignature(urlSignature) {
      this.signatureUrl = urlSignature;
      this.error = !(typeof urlSignature == "string" || urlSignature instanceof String);
    },
    async save(urlSignature) {
      if (typeof urlSignature == "string" || urlSignature instanceof String) {
        this.$emit("save", urlSignature);
        this.hide();
      } else {
        this.error = true;
      }
    },
  },
};
</script>

<style scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
