<template>
  <div>
    <div class="card mt-4 mx-4">
      <div class="card-body">
        <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
          <form ref="form" method="post" @submit.prevent="handleSubmit()" >
            <PTabView :activeIndex.sync="activeTab">
              <PTabPanel header="Configuration de base">
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <input v-model="setting.seuilTauxRemplissageProfilFinOc" type="text" class="form-control" id="seuilTauxRemplissageProfilFinOc" name="seuilTauxRemplissageProfilFinOc">
                        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                        <label for="seuilTauxRemplissageProfilFinOc">Seuil taux remplissage de dossier pour DE</label>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <input v-model="setting.seuilTauxRemplissageProfilEntreprise" type="text" class="form-control" id="seuilTauxRemplissageProfilEntreprise" name="seuilTauxRemplissageProfilEntreprise">
                        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                        <label for="seuilTauxRemplissageProfilEntreprise">Seuil taux remplissage de dossier pour ENTREPRISE</label>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </PTabPanel>
              <PTabPanel header="Notifications">
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <h6>Cannaux de notifications pour validation dossier DE</h6>
                    <div>
                      <multiselect
                        v-model="setting.cannauxNotificationValidationDossierDemandeur"
                        :options="['SMS', 'MAIL', 'FCM']"
                        :multiple="true"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Cannaux de notification"
                        :preselect-first="false"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6"></div>
                </div>
              </PTabPanel>
              <PTabPanel header="Paramètres SMTP">
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <input v-model="setting.smtpUsername" type="text" class="form-control" id="smtpUsername" name="smtpUsername">
                        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                        <label for="smtpUsername">SMTP USERNAME</label>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <input v-model="setting.smtpHost" type="text" class="form-control" id="smtpHost" name="smtpHost">
                        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                        <label for="smtpUsername">SMTP HOST</label>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <input v-model="setting.smtpPort" type="text" class="form-control" id="smtpPort" name="smtpPort">
                        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                        <label for="smtpUsername">SMTP PORT</label>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <ValidationProvider rules="" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <input v-model="setting.smtpPassword" type="password" class="form-control" id="smtpPassword" name="smtpPassword">
                        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                        <label for="smtpPassword">SMTP PASSWORD</label>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </PTabPanel>
              <PTabPanel header="Paramètres SMS">

              </PTabPanel>
              <PTabPanel header="Paramètres FCM">

              </PTabPanel>
              <PTabPanel header="Signature du DG">
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <input v-model="setting.nomDG" type="text" class="form-control" id="nomDG" name="nomDG">
                        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                        <label for="nomDG">Nom du DG</label>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <input v-model="setting.prenomDG" type="text" class="form-control" id="prenomDG" name="prenomDG">
                        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                        <label for="prenomDG">Prénom du DG</label>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div col-md-12>
                    <div v-if="setting.signatureDG != null && setting.signatureDG.length != null">
                      <img :src="setting.fullSignatureDGUrl" alt="">
                    </div>
                    <SignatureEditorOrUploadDialog
                      @save="onSignatureSigned"
                      ref="signatureEditor"
                    />
                    <button class="btn btn-primary mt-3" @click.prevent="showSignatureEditor">Ajouter signature</button>
                  </div>
                </div>
              </PTabPanel>
            </PTabView>
          </form>
        </ValidationObserver>
        <PDivider />
        <div class="d-flex justify-content-end">
          <button class="btn btn-danger" @click.prevent="cancel">Annuler</button>
          <button class="btn btn-success ms-2" @click.prevent="save">Sauvegarder</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import SignatureEditorOrUploadDialog from "../../../components/espace/common/signature/SignatureEditorOrUploadDialog.vue";

export default {
  data() {
    return {
      items: [],
      activeTab: 0,
      setting: {}
    };
  },
  components: {
    Multiselect,
    SignatureEditorOrUploadDialog
  },
  watch: {},
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      fetchParametre: 'parametre/fetchParametre',
      updateParametre: 'parametre/updateParametre'
    }),
    save(){
      this.updateParametre(this.setting).then((data) => {
        this.setting = data
      })
    },
    showSignatureEditor(event, item){
      this.$refs.signatureEditor.show()
    },
    onSignatureSigned(signatureUrl){
      this.setting.signatureDG = signatureUrl
      this.save()

    },
    cancel(){
      this.loadData()
    },
    loadData(){
      this.fetchParametre().then(data => {
        this.setting = data
      })
    }
  },
};
</script>

<style></style>
